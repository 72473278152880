<template>
  <div>
    <Back title="隐私设置" />
    <div :class="$style.content">
      NAO艺术平台致力于保护您的隐私。
      <br />
      1. 联系公司
      如果您对我们的隐私政策或我们的数据收集或处理做法有任何问题、疑虑或投诉，或者您想向我们报告任何安全违规行为，请通过以下电子邮件地址与我们联系：contact@vsk.tech。
      <br />
      2.用户同意
      通过我们的网站或服务提交个人数据，即表示您同意本隐私政策的条款，并且您明确同意根据本隐私政策收集、使用和披露您的个人数据。为了进行数字作品交易，允许我们使用第三方支付平台。
      <br />
      3. 关于儿童的说明
      我们不会故意从18岁以下的访问者那里收集个人数据，如果18岁以下的未成年人向公司提交个人数据，并且我们了解到个人数据是18岁以下未成年人的信息，我们将尝试尽早删除该信息。如果您认为我们可能拥有18岁以下未成年人的任何个人数据，请通过第
      (1) 段中指定的地址与我们联系。
      <br />
      4. 中国境外用户须知
      如果您是本网站的非中国用户，通过访问本网站并向我们提供数据，您承认并同意您的个人数据可能会被处理用于隐私政策中确定的目的。此外，您的个人数据可能会在收集数据的国家和其他国家/地区（包括中国）进行处理，在这些国家/地区，有关个人数据处理的法律可能不如您所在国家/地区的法律严格。提供您的数据，即表示您同意此类传输。
      <br />
      5. 我们收集的数据类型
      “个人数据”是指允许某人识别或与您联系的数据，包括例如您的姓名、地址、电话号码、电子邮件地址，以及与您相关联或链接的任何其他非公开信息任何上述数据。“匿名数据”是指与您的个人数据无关或链接的数据，包括汇总和去标识化数据；匿名数据本身不允许识别个人。我们收集个人数据和匿名数据，如下所述一种，您提供给我们的信息。
      <br />
      ▪
      当您创建一个帐户以登录我们的网络（“帐户”）时，我们可能会收集您的个人数据，例如您的姓名、电子邮件和邮寄地址以及密码。
      <br />
      ▪ 如果您在移动设备上使用我们的服务，我们可能会收集您的电话号码和唯一的设备
      ID 号码。
      <br />
      ▪
      我们的站点允许您存储偏好，例如您的内容的显示方式、您的位置、安全搜索设置和最喜欢的小部件。我们可能会将这些选择与您的
      ID、浏览器或移动设备相关联，您可以随时编辑这些首选项。
      <br />
      ▪
      当通过唯一标识您的移动设备的服务提供商连接到我们的服务时，我们可能会收到此标识并使用它来提供扩展服务和/或功能。
      <br />
      ▪
      某些服务，例如两步验证，可能需要我们收集您的电话号码。我们可能会将该电话号码与您的移动设备识别信息相关联。
      <br />
      ▪
      如果您向我们提供反馈或通过电子邮件与我们联系，我们将收集您的姓名和电子邮件地址，以及电子邮件中包含的任何其他内容，以便向您发送回复。
      <br />
      ▪
      我们还会收集您自愿提供给我们的其他类型的个人数据，例如您的操作系统和版本、产品注册号，以及如果您通过电子邮件与我们联系以获取服务支持所需的其他信息。
      <br />
      ▪
      我们也可能会在我们网站的其他表明正在收集个人数据或通过技术收集的信息。当您浏览我们的网站并与之互动时，我们可能会使用自动数据收集技术来收集有关您的设备、浏览行为和模式的某些信息，包括：
      <br />
      ▪
      为了使我们的网站和服务对您更有用，我们的服务器（可能由第三方服务提供商托管）从您那里收集信息，包括您的浏览器类型、操作系统、互联网协议（“IP”）地址（当您使用互联网时自动分配给您的计算机的数字）
      ，这可能因会话而异）、域名和/或您访问的日期/时间戳。
      <br />
      ▪
      与大多数网站一样，我们会自动收集某些信息并将其存储在日志文件中。此信息包括
      IP 地址、浏览器类型、互联网服务提供商
      (“ISP”)、引用/退出页面、操作系统、日期/时间戳和点击流数据。我们使用这些信息来分析趋势、管理网站、跟踪用户在网站上的活动、收集关于我们整个用户群的人口统计信息，并更好地根据用户的需求定制我们的服务。例如，可能会收集某些信息，以便当您再次访问本网站或服务时，它会识别您，然后这些信息可用于提供适合您兴趣的广告和其他信息。
      <br />
      ▪ 像许多在线服务一样，我们使用 cookie
      来收集信息。“Cookies”是网站在您浏览网站时发送到您计算机硬盘的小块信息。我们可能会同时使用会话
      Cookie（一旦您关闭您的网络浏览器就会过期）和持久性
      Cookie（在您将其删除之前会一直保留在您的计算机上），以便在我们的网站上为您提供更加个性化和互动的体验。收集此类信息是为了使本网站对您更有用，并定制我们的体验以满足您的特殊兴趣和需求。
      <br />
      ▪ 此外，我们使用“像素标签”（也称为清晰的
      Gif、网络信标或网络错误）。像素标签是具有唯一标识符的微小图形图像，其功能类似于
      Cookie，用于跟踪 Web 用户的在线活动。与存储在用户计算机硬盘驱动器上的
      Cookie
      相比，像素标签以隐形方式嵌入在网页中。像素标签还允许我们以用户可以阅读的格式发送电子邮件消息，它们会告诉我们电子邮件是否已打开，以确保我们只发送用户感兴趣的消息。我们可能会使用这些信息来减少或消除发送给用户的消息。我们不会将
      Pixel Tags 收集的信息与我们用户的个人数据联系起来。
      <br />
      ▪ 我们目前不响应“不跟踪”信号或其他可能使用户选择退出我们网站跟踪的机制。
      <br />
      ▪ 快速 LSO，当我们发布视频时，第三方可能会使用本地共享对象（称为“Flash
      Cookie”）来存储您对音量控制的偏好或对某些视频功能进行个性化设置。Flash
      Cookie 与浏览器 Cookie
      不同，因为数据的数量和类型以及数据的存储方式。您的浏览器提供的 Cookie
      管理工具不会删除 Flash Cookie。
      <br />
      ▪
      除了我们放置的跟踪技术外，其他公司可能会在您访问我们的网站时设置他们自己的
      Cookie
      或类似工具。这包括第三方分析服务，包括但不限于谷歌分析（“分析服务”），我们参与帮助分析用户如何使用网站。我们可能会收到基于这些方单独或汇总使用这些工具的报告。我们仅使用从分析服务获得的信息来改进我们的网站和服务。由
      Cookie
      或其他技术生成的有关您使用我们网站和服务的信息（“分析信息”）将传输到分析服务。分析服务使用分析信息来编制用户活动报告。分析服务也可能在法律要求的情况下将信息传输给第三方，或者此类第三方代表他们处理分析信息。每个分析服务使用和共享分析信息的能力受到此类分析服务的使用条款和隐私政策的限制。使用我们的网站和服务，即表示您同意分析服务以上述方式和目的处理有关您的数据。如需分析服务的完整列表，请通过第
      (1)
      段中提供的地址与我们联系。从您那里收集的关于他人的信息，从第三方公司收集的信息，我们可能会从通过联合品牌或自有品牌网站提供我们服务的公司或在我们的网站上提供其产品和/或服务的公司接收有关您的个人和/或匿名数据。这些第三方公司可能会向我们提供个人数据。我们可能会将此添加到我们已经通过我们的网站从您那里收集的信息中，以改进我们提供的服务。我们不会自动收集个人数据，但我们可能会将我们自动收集的信息与我们从其他来源收集的或您提供给我们的有关您的个人数据联系起来。
      <br />
      6. 使用您的个人数据 一般使用
      一般而言，您提交给我们的个人数据用于回应您提出的请求，或帮助我们更好地为您服务。我们通过以下方式使用您的个人数据：
      <br />
      ▪ 促进在我们的网络上创建和保护您的帐户；
      <br />
      ▪ 在我们的系统中将您识别为用户；
      <br />
      ▪ 改进对我们网站和服务的管理；
      <br />
      ▪ 提供您请求的服务，包括但不限于通过微信、支付宝等促进您的数字作品交易；
      <br />
      ▪ 提高您与我们的网站和服务互动时的体验质量；
      <br />
      ▪ 向您发送短信，以验证您在创建帐户时提供的手机号码所有权；
      <br />
      ▪
      保护您和其他用户免受任何违反使用条款的行为或防止滥用或骚扰任何用户的行为；
      <br />
      ▪ 在您网站上出售的数字作品旁边显示您的用户名；
      <br />
      ▪ 向您发送管理短信通知，例如安全或支持和维护建议；
      <br />
      ▪ 回应您对就业机会或其他要求的询问；
      <br />
      ▪ 不时给您打电话，作为二级欺诈保护的一部分或征求您的反馈意见；
      <br />
      ▪ 以我们在您提供个人数据时可能描述的任何其他方式；
      <br />
      ▪
      发送与我们的服务和公司其他营销目的相关的时事通讯、调查、优惠和其他宣传材料。
      我们可能会使用您的个人数据就您可能感兴趣的我们自己和第三方的商品和服务与您联系。创建匿名数据，我们可能会通过排除使数据可识别您个人身份的信息（例如您的姓名）来从个人数据创建匿名数据记录。我们使用这些匿名数据来分析请求和使用模式，以便我们可以增强我们服务的内容并改进站点导航。我们保留出于任何目的使用匿名数据并不受限制地向第三方披露匿名数据的权利。
      <br />
      7. 披露您的个人数据
      我们会按照下文所述以及本隐私政策其他地方的说明披露您的个人数据。一种，第三方服务提供商，我们可能会与第三方服务提供商共享您的个人数据，向您提供我们通过我们的网站向您提供的服务；进行质量保证测试；促进帐户的创建；提供技术支持；或向本公司提供其他服务。附属公司，我们可能会与我们的母公司、子公司、合资企业或其他受共同控制的公司（“关联公司”）共享您的部分或全部个人数据，在这种情况下，我们将要求我们的关联公司遵守本隐私政策。企业重组，我们可能会在与任何合并、融资、收购或解散交易或涉及出售、转让、剥离或披露我们全部或部分业务或资产的程序。在资不抵债、破产或接管的情况下，个人数据也可能作为商业资产转移。如果另一家公司收购了我们的公司、业务或资产，则该公司将拥有我们收集的个人数据，并将承担本隐私政策中所述的与您的个人数据相关的权利和义务。根据法律要求，无论您对您的个人数据做出任何选择（如下所述），如果公司真诚地认为此类披露是必要的，则公司可能会披露个人数据
      (a) 与任何法律调查有关；(b)
      遵守相关法律或回应送达给公司的传票或认股权证；(c)
      保护或捍卫公司或网站或服务用户的权利或财产； (d)
      调查或协助防止任何违反或可能违反法律、本隐私政策或我们的使用条款的行为。e.
      其他披露。我们也可能会披露您的个人数据，以实现您提供的目的，用于我们在您提供时披露的任何其他目的或征得您的同意。
      <br />
      8. 第三方网站
      我们的网站可能包含第三方网站的链接，当您点击任何其他网站或位置的链接时，您将离开我们的网站并前往另一个网站，另一个实体可能会从您那里收集个人数据或匿名数据。我们无法控制、不审查这些外部网站或其内容，也不对其负责。请注意，本隐私政策的条款不适用于这些外部网站或内容，也不适用于您点击此类外部网站的链接后对您个人数据的任何收集。我们鼓励您阅读您访问的每个网站的隐私政策。第三方网站或位置的链接是为了您的方便，并不表示我们对此类第三方或其产品、内容或网站的认可。
      <br />
      9. 您对信息的选择
      关于使用我们服务的信息，您有多种选择：电子邮件通讯，我们会定期向您发送免费时事通讯和电子邮件，直接宣传我们网站或服务的使用。当您收到我们的时事通讯或促销通讯时，您可以表明偏好停止接收我们的进一步通讯，并且您将有机会按照您收到的电子邮件中提供的退订说明或联系直接联系我们（请参阅下面的联系信息）。尽管您指定了电子邮件偏好，我们仍可能向您发送与服务相关的通信，包括我们的使用条款或隐私政策的任何更新通知。
      如果您在任何时候决定不再出于上述任何目的从我们的服务中接受
      Cookie，那么您可以通过更改其设置来指示您的浏览器停止接受 Cookie
      或在接受来自我们服务的 Cookie
      之前提示您您访问的网站。查阅浏览器的技术信息。但是，如果您不接受
      Cookie，您可能无法使用服务的所有部分或服务的所有功能。如果您对如何禁用或修改
      Cookie
      有任何疑问，请通过下面提供的联系信息告诉我们。更改或删除您的个人数据，所有用户都可以通过联系我们或通过服务编辑他们的个人资料来查看、更新、更正或删除用户在其用户帐户（包括任何导入的联系人）中提供的个人数据。如果您完全删除所有个人数据，则您的用户帐户可能会被停用。我们将尽商业上合理的努力来满足您的要求。我们可能会根据法律要求或出于合法商业目的保留您的记录的存档副本。
      <br />
      10.本隐私政策的变更
      本隐私政策可能因任何原因不时更新，我们将通过发布新的隐私政策来通知您隐私政策的任何更改。在本隐私政策的开头标明了隐私政策的最后修订日期。您有责任确保我们为您提供最新的有效且可交付的电子邮件地址，并定期访问我们的网站和本隐私政策以检查任何更改。
      <br />
      11.您授权我们收集并与我们的支付提供商共享您的个人信息，包括全名、电子邮件地址和财务信息，并且您对该数据的准确性和完整性负责。
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
export default {
  components: {
    Back,
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 20px;
  line-height: 1.5;
}
</style>
